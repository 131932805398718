import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Modal from "../../../../../../../components/common/v2/modal";
import { OrderItemStatus } from "../../../../../../../constants/defaults";

import useOrderItem from "../../../hooks/useOrderItem";

const ModalOrderItemDetail = ({ data, userScope }) => {
  const {
    Id,
    CustomerIp,
    CustomerCountryCode,
    Status,
    StatusNotes = [],
  } = data || {};
  const { onRetry, onDeleteError } = useOrderItem({ data, userScope });

  let submit = null;

  switch (Status) {
    case OrderItemStatus.OutOfStock:
      submit = {
        onClick: onRetry,
        title: "Retry",
      };
      break;
    case OrderItemStatus.BackOrdered:
      submit = {
        onClick: onRetry,
        title: "Retry",
      };
      break;
    case OrderItemStatus.PreCompleted:
      submit = {
        onClick: onRetry,
        title: "Retry",
      };
      break;
    case OrderItemStatus.Error:
      submit = {
        onClick: onDeleteError,
        title: "Delete",
        theme: "danger",
      };
      break;
    default:
      break;
  }

  return (
    <Modal
      trigger={{ theme: "light", title: <i className="fas fa-eye"></i> }}
      accessibility={{ submit }}
      title="Order Item Detail"
    >
      <div
        className="d-flex flex-column align-items-center gap-4"
        style={{ fontSize: 14 }}
      >
        <div className="d-flex flex-column align-items-center gap-2">
          <h6 className="m-0">Id</h6>
          <span>{Id}</span>
        </div>
        <div className="d-flex flex-column align-items-center gap-2">
          <h6 className="m-0">Status</h6>
          <span>{Status}</span>
        </div>
        <div className="d-flex flex-column align-items-center gap-2">
          <h6 className="m-0">History</h6>
          <div
            className="d-flex flex-column align-items-center gap-1 overflow-auto"
            style={{ maxHeight: 200 }}
          >
            {StatusNotes?.map((e, i) => (
              <span key={i}>{e}</span>
            ))}
          </div>
        </div>
        <div className="d-flex flex-column align-items-center gap-2">
          <h6 className="m-0">Customer</h6>
          <span>{CustomerIp}</span>
          <span>{CustomerCountryCode}</span>
        </div>
      </div>
    </Modal>
  );
};

export default ModalOrderItemDetail;
