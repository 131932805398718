export default (req) => ({
  getCampaignsFromPartner: (
    keywords,
    validfrom,
    validto,
    pageindex,
    pagesize,
    name,
    sku,
    mSku,
    ReleaseDateFrom,
    ReleaseDateTo,
    PublisherIds,
    MinPrice,
    MaxPrice,
    OperatingSystems,
    Platforms,
    SortBy
  ) =>
    req.get("/merkurpromotion/Search", {
      params: {
        Keywords: keywords,
        ValidFrom: validfrom,
        ValidTo: validto,
        PageIndex: pageindex,
        PageSize: pagesize,
        name: name,
        sku: sku,
        mSku: mSku,
        ReleaseDateFrom: ReleaseDateFrom,
        ReleaseDateTo: ReleaseDateTo,
        PublisherIds: PublisherIds,
        MinPrice: MinPrice,
        MaxPrice: MaxPrice,
        OperatingSystems: OperatingSystems,
        Platforms: Platforms,
        SortBy: SortBy,
      },
    }),
  getDirectSalesFromPartner: (
    keywords,
    validfrom,
    validto,
    pageindex,
    pagesize,
    name,
    sku,
    mSku,
    ReleaseDateFrom,
    ReleaseDateTo,
    PublisherIds,
    MinPrice,
    MaxPrice,
    OperatingSystems,
    Platforms,
    SortBy
  ) =>
    req.get("/merkurpromotion/DirectSales/Search", {
      params: {
        Keywords: keywords,
        ValidFrom: validfrom,
        ValidTo: validto,
        PageIndex: pageindex,
        PageSize: pagesize,
        name: name,
        sku: sku,
        mSku: mSku,
        ReleaseDateFrom: ReleaseDateFrom,
        ReleaseDateTo: ReleaseDateTo,
        PublisherIds: PublisherIds,
        MinPrice: MinPrice,
        MaxPrice: MaxPrice,
        OperatingSystems: OperatingSystems,
        Platforms: Platforms,
        SortBy: SortBy,
      },
    }),

  getCampaignFromManagement: (campaignId, includePrices = true) =>
    req.get("/merkurpromotion/management/" + campaignId, {
      params: {
        IncludePrices: includePrices,
      },
    }),
  getCampaignsFromManagement: (
    keywords,
    validfrom,
    validto,
    pageindex,
    pagesize,
    name,
    sku,
    mSku,
    ReleaseDateFrom,
    ReleaseDateTo,
    PublisherIds,
    MinPrice,
    MaxPrice,
    SortBy,
    OperatingSystems,
    Platforms,
    Published,
    PromotionStatus,
    ProviderIds
  ) =>
    req.get("/merkurpromotion/management/Search", {
      params: {
        Keywords: keywords,
        ValidFrom: validfrom,
        ValidTo: validto,
        PageIndex: pageindex,
        PageSize: pagesize,
        name: name,
        sku: sku,
        mSku: mSku,
        ReleaseDateFrom: ReleaseDateFrom,
        ReleaseDateTo: ReleaseDateTo,
        PublisherIds: PublisherIds,
        MinPrice: MinPrice,
        MaxPrice: MaxPrice,
        SortBy: SortBy,
        OperatingSystems: OperatingSystems,
        Platforms: Platforms,
        Published: Published,
        PromotionStatus: PromotionStatus,
        ProviderIds: ProviderIds,
      },
    }),
  createPromotion: (
    productId,
    providerPriceIds,
    tier1Price,
    tier2Price,
    tier3Price,
    validFrom,
    validTo,
    minQuantity,
    maxQuantity,
    operatingSystems,
    platforms,
    countries,
    region = null
  ) =>
    req.post("/merkurpromotion/management/CreateMerkurPromotion", {
      productId: productId,
      providerPriceIds: providerPriceIds,
      tier1Price: tier1Price,
      tier2Price: tier2Price,
      tier3Price: tier3Price,
      validFrom: validFrom,
      validTo: validTo,
      minQuantity: minQuantity,
      maxQuantity: maxQuantity,
      operatingSystems: operatingSystems,
      platforms: platforms,
      countries: countries,
      published: true,
      region,
    }),
  updatePromotion: (
    campaignId,
    productId,
    providerPriceIds,
    tier1Price,
    tier2Price,
    tier3Price,
    validFrom,
    validTo,
    minQuantity,
    maxQuantity,
    operatingSystems,
    platforms,
    countries,
    region = null
  ) =>
    req.put(
      "/merkurpromotion/management/" + campaignId + "/UpdateMerkurPromotion",
      {
        productId: productId,
        OriginalPriceIds: providerPriceIds,
        tier1Price: tier1Price,
        tier2Price: tier2Price,
        tier3Price: tier3Price,
        validFrom: validFrom,
        validTo: validTo,
        minQuantity: minQuantity,
        maxQuantity: maxQuantity,
        operatingSystems: operatingSystems,
        platforms: platforms,
        countries: countries,
        published: true,
        region,
      }
    ),
  changePublishStatus: (promotionId) =>
    req.put(`/merkurpromotion/management/${promotionId}/ChangeStatus`),
  upcomingPromotions: (pageIndex, pageSize) =>
    req.get("/merkurpromotion/management/upcoming", {
      params: {
        PageIndex: pageIndex,
        PageSize: pageSize,
      },
    }),
  upcomingProviderPromotions: (pageIndex, pageSize) =>
    req.get("/merkurpromotion/management/upcomingProviderPromotions", {
      params: {
        PageIndex: pageIndex,
        PageSize: pageSize,
      },
    }),
  activeProviderPromotions: (pageIndex, pageSize) =>
    req.get("/merkurpromotion/management/activeProviderPromotions", {
      params: {
        PageIndex: pageIndex,
        PageSize: pageSize,
      },
    }),
  upcomingPromotionsPartner: (pageIndex, pageSize) =>
    req.get("/merkurpromotion/upcoming", {
      params: {
        PageIndex: pageIndex,
        PageSize: pageSize,
      },
    }),
  changeUpcomingStatus: (upComingId, partnerIds) =>
    req.put(`/merkurpromotion/management/${upComingId}/upcoming/Update`, {
      PartnerIds: partnerIds,
    }),

  updateProductPriceUpdateWithActiveDiscountRate: (publisherId) =>
    req.put(
      `/merkurpromotion/management/${publisherId}/ProductPriceUpdateWithActiveDiscountRate`,
      {}
    ),

  isProductAlreadyCreated: (promotionId) =>
    req.get(`/merkurpromotion/management/${promotionId}/CheckPromotion`),
});
