import React, { useCallback, useRef, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import Modal from "../../../../../../../components/common/v2/modal";

import approve from "./approve";
import cancel from "./cancel";
import close from "./close";
import pause from "./pause";
import refund from "./refund";
import refuse from "./refuse";
import resume from "./resume";
import revoke from "./revoke";

const ModalUpdateStatus = ({ order, userScope, type }) => {
  const refForm = useRef();

  if (!order) {
    return <span>Order data is not provided</span>;
  }

  let config = null;

  switch (type) {
    case "Approve":
      config = approve;
      break;
    case "Cancel":
      config = cancel;
      break;
    case "Close":
      config = close;
      break;
    case "Pause":
      config = pause;
      break;
    case "Refund":
      config = refund;
      break;
    case "Refuse":
      config = refuse;
      break;
    case "Resume":
      config = resume;
      break;
    case "Revoke":
      config = revoke;
      break;
    default:
      break;
  }

  if (!config) {
    return null;
  }

  const { isEnabled, modal } = config(order, userScope);

  if (!isEnabled) {
    return null;
  }

  const submit = modal.accessibility?.submit
    ? {
        ...modal.accessibility.submit,
        onClick: async () => {
          const values = modal.form?.reduce((prev, curr) => {
            const element = refForm.current[curr.key];

            if (!element) throw new Error(`input ${curr.key} is not available`);

            const value = curr.map ? curr.map(element) : element.value;
            return { ...prev, [curr.key]: value };
          }, {});

          await modal.accessibility.submit.onClick(values);

          setTimeout(() => {
            window.location.reload();
          }, 500);
        },
      }
    : null;

  const accessibility = modal.accessibility
    ? { ...modal.accessibility, submit }
    : null;

  return (
    <Modal {...modal} accessibility={accessibility}>
      <div className="d-flex flex-column gap-4">
        <span>{modal.description}</span>
        <div className="d-flex flex-column gap-1">
          <h6 className="m-0">Order No</h6>
          <span>{order.OrderNumber}</span>
        </div>
        <form ref={refForm}>
          {modal.form?.map((item) => (
            <div key={item.key} className="d-flex flex-column gap-1">
              <h6 className="m-0">{item.title}</h6>
              <input
                id={item.key}
                name={item.key}
                type={item.type}
                defaultChecked={item.defaultChecked}
                defaultValue={item.defaultValue}
              />
            </div>
          ))}
        </form>
      </div>
    </Modal>
  );
};

export default ModalUpdateStatus;
