import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";

import api from "../../../../../../api";
import Loader from "../../../../../../components/Loader";
import Table from "../../../../../../components/common/v2/table";
import { OrderItemStatus } from "../../../../../../constants/defaults";
import Filter from "../../../../../../components/Filter";
import OrderItemStatusText from "../../../../../../components/common/v2/status/order-item-status";
import Button from "../../../../../../components/common/v2/button";
import ModalOrderItemDetail from "../modals/order-item-detail";
import ModalOrderItemRetry from "../modals/order-item-retry";
import Dropdown from "../../../../../../components/common/v2/dropdown";
import useOrderItem from "../../hooks/useOrderItem";
import { element } from "prop-types";
import ModalOrderItemRefundAllBackOrdered from "../modals/order-item-refund-all-backordered";
import ModalOrderItemDeleteAllError from "../modals/order-item-delete-all-error";
import ModalOrderItemDelete from "../modals/order-item-delete";

const OrderItemActionColumn = ({ data, filter }) => {
  const { onRetryAll, onRefundAllBackOrdered } = useOrderItem({ data });

  const canDelete = filter?.length === 1 && filter[0] === OrderItemStatus.Error;
  const canRetry =
    filter?.includes?.(OrderItemStatus.BackOrdered) ||
    filter?.includes?.(OrderItemStatus.OutOfStock) ||
    filter?.includes?.(OrderItemStatus.PreCompleted);
  const canRefund =
    filter?.includes?.(OrderItemStatus.BackOrdered) ||
    filter?.includes?.(OrderItemStatus.OutOfStock);

  if (!canDelete && !canRetry && !canRefund) return null;

  return (
    <Dropdown
      trigger={{ theme: "light", title: "Actions" }}
      items={[
        canRetry && {
          title: "Retry All",
          onClick: onRetryAll,
        },
        canRefund && {
          title: <ModalOrderItemRefundAllBackOrdered data={data} />,
        },
        canDelete && {
          title: <ModalOrderItemDeleteAllError data={data} />,
        },
      ].filter((e) => e)}
    />
  );
};

const OrderItemActionItem = ({ data }) => {
  return (
    <div className="d-flex flex-column align-items-center gap-2">
      <ModalOrderItemDetail data={data} />
      <ModalOrderItemRetry data={data} />
      <ModalOrderItemDelete data={data} />
    </div>
  );
};

const OrderItemsTab = ({ order = {} }) => {
  const [filter, setFilter] = useState({ state: [] });

  const fetchData = useCallback(
    (page) =>
      api.orders.getOrderItems(
        order.Id,
        page.PageIndex,
        page.PageSize,
        filter.state.join(",")
      ),
    [filter, order]
  );

  return (
    <div className="">
      <Filter
        hideSearch
        onFilter={(e) =>
          setFilter((prev) => ({ ...prev, state: e.filter.Status }))
        }
        orderItemStatusList={[
          OrderItemStatus.Completed,
          OrderItemStatus.PreCompleted,
          OrderItemStatus.BackOrdered,
          OrderItemStatus.OutOfStock,
          OrderItemStatus.Error,
        ]}
      />
      <Table
        fetchData={fetchData}
        columns={[
          {
            key: "CustomerIp",
            title: "IP",
          },
          {
            key: "CustomerCountryCode",
            title: "Country",
          },
          {
            key: "PackedOnUtc",
            title: (
              <span>
                Package Date<sup>UTC</sup>
              </span>
            ),
          },
          {
            key: "CompletedOnUtc",
            title: (
              <span>
                Completed Date<sup>UTC</sup>
              </span>
            ),
          },
          {
            key: "StatusItem",
            title: "Status",
          },
        ]}
        mapper={(e) => ({
          ...e,
          PackedOnUtc: e.PackedOnUtc
            ? moment.utc(e.PackedOnUtc).format("DD.MM.yyyy HH:mm:ss")
            : "-",
          CompletedOnUtc: e.CompletedOnUtc
            ? moment.utc(e.CompletedOnUtc).format("DD.MM.yyyy HH:mm:ss")
            : "-",
          StatusItem: <OrderItemStatusText data={e} />,
        })}
        ActionColumn={
          <OrderItemActionColumn
            data={{ OrderId: order.Id, Status: order.Status }}
            filter={filter.state}
          />
        }
        ActionItem={OrderItemActionItem}
      />
    </div>
  );
};

export default OrderItemsTab;
