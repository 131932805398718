import {
  OrderStatus,
  salesman,
  superadmin,
} from "../../../../../../../constants/defaults";

import api from "../../../../../../../api";

const approve = (
  { Id, Status, ProcessingStartOnUtc, ProcessingEndOnUtc, DontCreateInvoice },
  userScope
) => ({
  isEnabled:
    [OrderStatus.Submitted, OrderStatus.Confirmed].includes(Status) ||
    ([OrderStatus.Level1Approved].includes(Status) &&
      [superadmin, salesman].includes(userScope)),
  modal: {
    title: "Approve Order",
    accessibility: {
      submit: {
        onClick: ({ processStart, processEnd, donotCreateInvoice }) =>
          api.orders.approve(Id, processStart, processEnd, donotCreateInvoice),
        successMessage: "Order is approved, successfully",
        title: "Approve",
        theme: "success",
      },
    },
    trigger: {
      title: "Approve",
      theme: "success",
    },
    description: "Order will be approved.",
    form: [
      {
        defaultValue: ProcessingStartOnUtc || null,
        key: "processStart",
        title: "Process Start Date UTC",
        type: "datetime-local",
      },
      {
        defaultValue: ProcessingEndOnUtc || null,
        key: "processEnd",
        title: "Process End Date UTC",
        type: "datetime-local",
      },
      {
        defaultChecked: DontCreateInvoice || false,
        key: "donotCreateInvoice",
        title: "Don't Create Invoice",
        type: "checkbox",
        map: (e) => e.checked,
      },
    ],
  },
});

export default approve;
