import React, { useEffect, useState } from "react";

import api from "../../../../api";

import Content from "../../../../components/containers/Content";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Blending from "./components/blending";
import Loader from "../../../../components/Loader";
import ActionDeck from "./components/action-deck";
import Detail from "./components/detail";
import FinanceDetail from "./components/finance-detail";
import Tabs from "./components/tabs";
import moment from "moment";
import { OrderItemStatus } from "../../../../constants/defaults";
import LatestStatus from "./components/latest-status";

const Page = ({ match, user, ...props }) => {
  const id = match?.params?.orderid;
  const [data, setData] = useState({
    blending: null,
    promotion: null,
    order: null,
  });
  const [loading, setLoading] = useState(() => true);

  const userScope = user.scope?.[0];

  useEffect(() => {
    const clearState = () => {
      setData(null);
    };

    if (!id) return clearState;

    const fetchData = async () => {
      try {
        const [order, blending] = await Promise.all([
          api.orders.getManagementOrder(id),
          api.orders.getOrderBlending(id),
        ]);

        if (!order) throw new Error("Order is empty");

        if (!blending) throw new Error("Blending is empty");

        setData((prev) => ({
          ...prev,
          order,
          blending,
        }));

        if (!order.Product.VoiduPromotion.PromotionId)
          throw new Error("Order promotion data is empty");

        const promotion = await api.campaigns.getCampaignFromManagement(
          order.Product.VoiduPromotion.PromotionId,
          true
        );

        setData((prev) => ({
          ...prev,
          promotion,
        }));
      } catch (error) {
        toast.error(
          <div>
            {error?.response?.data.message || error.message || "Unknown error"}
          </div>
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return clearState;
  }, [id]);

  if (!id) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Content>
      <h3 className="d-flex gap-2">
        {data.order.Product.ProductName}
        <i hidden={!data.order.IsBilled} className="fas fa-file-invoice"></i>
      </h3>
      <LatestStatus order={data.order} />
      <div className="w-100">
        <div className="row m-0 p-0">
          <div className="col-12 col-xxl-7 ps-0">
            <Detail promotion={data.promotion} order={data.order} />
          </div>
          <div className="col-12 col-xxl-5 ps-0">
            <FinanceDetail order={data.order} />
          </div>
        </div>
      </div>
      <ActionDeck data={data} userScope={userScope} />
      <Blending
        blending={data.blending}
        promotion={data.promotion}
        order={data.order}
        userScope={userScope}
      />
      <Tabs order={data.order} />
    </Content>
  );
};

const OrderDetailPage = connect(
  (state) => ({
    user: state.auth.currentUser,
  }),
  (dispatch) => ({})
)(Page);

export default OrderDetailPage;
