export default (req) => ({
  getOrders: (
    pageindex,
    pagesize,
    states,
    keywords,
    productSku,
    priceFrom,
    priceTo,
    createdAtFrom,
    createdAtTo,
    IsDelivered,
    sortBy,
    isBilled
  ) =>
    req.get("/order/me/search", {
      params: {
        PageIndex: pageindex,
        PageSize: pagesize,
        States: states,
        Keywords: keywords,
        ProductSku: productSku,
        PriceFrom: priceFrom,
        PriceTo: priceTo,
        CreatedAtFrom: createdAtFrom,
        CreatedAtTo: createdAtTo,
        IsDelivered: IsDelivered,
        SortBy: sortBy,
        IsBilled: isBilled,
      },
    }),
  getOrder: (id) => req.get("/order/me/" + id),
  getManagementOrder: (id) => req.get("/order/management/" + id),
  getOrderBlending: (id) => req.get("/report/management/OrderBlending/" + id),
  updateManagementOrderBlending: (id, Countries) =>
    req.put("/order/management/" + id + "/UpdateOrderBlending", {
      Countries,
    }),
  getOrderItems: (orderid, pageindex, pagesize, states = "") => {
    return req.get(
      `/orderitem/management/${orderid}/GetItems?PageIndex=${pageindex}&PageSize=${pagesize}&States=${states}`
    );
  },
  deleteManagementOrderItem: (orderid, orderitemid) =>
    req.put(
      "/orderitem/management/" + orderid + "/DeleteOrderItem/" + orderitemid
    ),
  deleteManagementErrorOrderItem: (orderid) =>
    req.put("/orderitem/management/" + orderid + "/DeleteErrorItems/"),
  retryManagementOrderItem: (orderid, orderitemid) =>
    req.put(
      "/orderitem/management/" + orderid + "/GetOrderItemKey/" + orderitemid
    ),
  retryAllManagementOrderItem: (orderid) =>
    req.put("/orderitem/management/" + orderid + "/GetOrderItemKeys"),
  refundBackOrderedItems: (orderid) =>
    req.put(`/orderitem/management/${orderid}/RefundBackOrderedItems`),
  getManagementOrders: (
    pageindex,
    pagesize,
    states,
    keywords,
    productSku,
    priceFrom,
    priceTo,
    createdAtFrom,
    createdAtTo,
    sortBy,
    IsBilled,
    partnerIds,
    IsDelivered,
    productPublisher,
    ProviderIds
  ) =>
    req.get("/order/management/search/", {
      params: {
        PageIndex: pageindex,
        PageSize: pagesize,
        States: states,
        Keywords: keywords,
        ProductSku: productSku,
        PriceFrom: priceFrom,
        PriceTo: priceTo,
        CreatedAtFrom: createdAtFrom,
        CreatedAtTo: createdAtTo,
        SortBy: sortBy,
        IsBilled: IsBilled,
        PartnerIds: partnerIds,
        IsDelivered: IsDelivered,
        ProductPublisher: productPublisher,
        providerIds: ProviderIds,
      },
    }),
  withdraw: (orderid) => req.post("/order/me/actions/" + orderid + "/Withdraw"),
  resubmit: (quantity, price, orderid) =>
    req.post("/order/me/actions/" + orderid + "/submit", {
      Quantity: quantity,
      Price: price,
    }),
  confirm: (orderid) => req.post("/order/me/actions/" + orderid + "/confirm"),
  reject: (orderid) => req.post("/order/me/actions/" + orderid + "/reject"),
  approve: (
    orderid,
    processingStartOnUtc,
    processingEndOnUtc,
    dontCreateInvoice
  ) =>
    req.post("/order/management/actions/" + orderid + "/Approve", {
      ProcessingStartOnUtc: processingStartOnUtc,
      ProcessingEndOnUtc: processingEndOnUtc,
      DontCreateInvoice: dontCreateInvoice,
    }),
  refuse: (orderid) =>
    req.post("/order/management/actions/" + orderid + "/refuse"),
  revoke: (orderid) =>
    req.post("/order/management/actions/" + orderid + "/revoke"),
  rebid: (quantity, price, orderid) =>
    req.post("/order/management/actions/" + orderid + "/Rebid", {
      Quantity: quantity,
      Price: price,
    }),
  refund: (orderid) => req.post("/order/me/actions/" + orderid + "/refund"),
  refundManagement: (orderid) =>
    req.post("/order/management/actions/" + orderid + "/refund"),
  cancel: (orderid) => req.post("/order/me/actions/" + orderid + "/cancel"),
  close: (orderid, SendBackOrderedItemsToInventory) =>
    req.post("/order/management/actions/" + orderid + "/close", {
      SendBackOrderedItemsToInventory: SendBackOrderedItemsToInventory,
    }),
  createPackage: (orderid, quantity) =>
    req.post("/order/me/actions/" + orderid + "/CreatePackage", {
      Quantity: quantity,
    }),
  sendPackage: (orderid, packageid) =>
    req.post(
      "/order/me/actions/" + orderid + "/Packages/" + packageid + "/SendPackage"
    ),
  managementCreatePackage: (orderid, quantity) =>
    req.post("/order/management/actions/" + orderid + "/CreatePackage", {
      Quantity: quantity,
    }),
  managementSendPackage: (orderid, packageid) =>
    req.post(
      "/order/management/actions/" +
        orderid +
        "/Packages/" +
        packageid +
        "/SendPackage"
    ),
  createInvoice: (OrderIds) =>
    req.post("/invoice/management/create", { OrderIds: OrderIds }),
  myCreateInvoice: (OrderIds) =>
    req.post("/invoice/me/create", { OrderIds: OrderIds }),
  updateInvoiceCreatingStatus: (orderid) =>
    req.put(`/order/management/${orderid}/UpdateInvoiceCreatingStatus`),

  reprocess: (orderid) =>
    req.post("/order/management/actions/" + orderid + "/Reprocess"),
  pause: (orderid) =>
    req.post("/order/management/actions/" + orderid + "/Pause"),
  inventories: (pageIndex, pageSize, keywords, store) =>
    req.get("/orderitem/management/inventories", {
      params: {
        pageIndex: pageIndex,
        pageSize: pageSize,
        Keywords: keywords,
        Store: store,
      },
    }),
  InventorySummary: () => req.get("/orderitem/management/inventory/summary"),
  bulkOrder: (partnerId, items) =>
    req.post("/order/management/actions/SubmitBulkOrder", {
      partnerId: partnerId,
      items: items,
    }),
  submitOrderFromInventory: (partnerId, sku, price, quantity, store, type) =>
    req.post("/order/management/actions/SubmitOrderFromInventory", {
      partnerId: partnerId,
      sku: sku,
      price: price,
      quantity: quantity,
      store: store,
      type: type,
    }),
  inventoryCheck: (sku, type) =>
    req.get(
      `/order/management/search/?PageIndex=0&PageSize=20&ProductSku=${sku}&States=Submitted,SalesmanApproved&OrderType=` +
        type
    ),
  sendToInventory: (orderId, params) =>
    req.post(`/order/management/actions/${orderId}/SendToInventory`, params),
  getInventoryCount: (orderId) =>
    req.get(
      `/orderitem/management/${orderId}/GetCount?states=OutOfStock,PreCompleted`
    ),
  approveAll: (OrderIds) =>
    req.post(`/order/management/actions/ApproveAll`, {
      OrderIds: OrderIds,
    }),
  manualInventorySearch: (pagesize, pageindex, keywords, store) =>
    req.get(
      `/manualinventory/management/search?PageSize=${pagesize}&PageIndex=${pageindex}&Keywords=${keywords}&Store=${store}`
    ),
  manualInventoryUpsert: (productId, quantity, costPerItem) =>
    req.put("/manualinventory/management/upsert", {
      productId: productId,
      quantity: quantity,
      costPerItem: costPerItem,
    }),
  updateQuantityWithCompletedKeys: (orderid) =>
    req.put(
      "/order/management/" + orderid + "/UpdateQuantityWithCompletedKeys/"
    ),
  hardStopOrder: (orderid) =>
    req.put("/order/management/" + orderid + "/HardStop/"),
  changePaidStatus: (orderId, paidStatus) =>
    req.post(
      `/order/management/actions/${orderId}/UpdateOrderPaidStatus/${paidStatus}`
    ),
  getOrderProcessByStatus: (pageIndex = 0, pageSize = 50) =>
    req.get("/order/management/OrderProcessByStatus", {
      params: {
        PageIndex: pageIndex,
        PageSize: pageSize,
      },
    }),
  updateOrderOfferPrice: (orderId, offerPrice) =>
    req.post(`/order/management/actions/UpdateOrderOfferPrice`, {
      OrderId: orderId,
      OfferPrice: offerPrice,
    }),
  getPartnerUnInvoicedOrders: (
    pageindex,
    pagesize,
    states,
    keywords,
    productSku,
    priceFrom,
    priceTo,
    createdAtFrom,
    createdAtTo,
    sortBy,
    IsBilled,
    partnerIds,
    IsDelivered,
    productPublisher,
    ProviderIds
  ) =>
    req.get("/order/me/search/", {
      params: {
        PageIndex: pageindex,
        PageSize: pagesize,
        States: states,
        Keywords: keywords,
        ProductSku: productSku,
        PriceFrom: priceFrom,
        PriceTo: priceTo,
        CreatedAtFrom: createdAtFrom,
        CreatedAtTo: createdAtTo,
        SortBy: sortBy,
        IsBilled: IsBilled,
        PartnerIds: partnerIds,
        IsDelivered: IsDelivered,
        ProductPublisher: productPublisher,
        providerIds: ProviderIds,
      },
    }),
  update: (
    orderId,
    {
      PromotionId,
      ReprocessBlending,
      Quantity,
      OfferPrice,
      ProcessingStartOnUtc,
      ProcessingEndOnUtc,
      DontCreateInvoice,
    }
  ) =>
    req.post(`/order/management/${orderId}`, {
      PromotionId,
      ReprocessBlending,
      Quantity,
      OfferPrice,
      ProcessingStartOnUtc,
      ProcessingEndOnUtc,
      DontCreateInvoice,
    }),
});
