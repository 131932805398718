import React, { useCallback } from "react";

import { OrderStatus } from "../../../../../../../constants/defaults";

import Modal from "../../../../../../../components/common/v2/modal";
import api from "../../../../../../../api";

const ModalHardStop = ({ data, userScope }) => {
  const { order } = data || {};

  const onSubmit = useCallback(async () => {
    await api.orders.hardStopOrder(order.Id);

    setTimeout(() => {
      window.location.reload();
    }, 500);
  }, [order]);

  if (!order) {
    return <span>Order data is not provided.</span>;
  }

  if (
    ![
      OrderStatus.Processing,
      OrderStatus.Paused,
      OrderStatus.OutOfStock,
      OrderStatus.PromotionExpired,
    ].includes(order.Status)
  ) {
    return null;
  }

  return (
    <Modal
      accessibility={{
        submit: {
          onClick: onSubmit,
          successMessage: "The order has been forced to stop, successfully!",
          title: "Confirm",
        },
      }}
      trigger={{ theme: "danger", title: "Hard stop" }}
      title="Order will be stopped, are you sure to process?"
    >
      <div className="d-flex flex-column gap-2">
        <div className="d-flex">
          <span className="w-50">
            <b>Order Number:</b>
          </span>
          <span className="w-50">{order.OrderNumber}</span>
        </div>

        <div className="d-flex">
          <span className="w-50">
            <b>Status:</b>
          </span>
          <span className="w-50">{order.Status}</span>
        </div>
        <div className="d-flex">
          <span className="w-50">
            <b>Product Name:</b>
          </span>
          <span className="w-50">{order.Product.ProductName}</span>
        </div>
        <div className="d-flex">
          <span className="w-50">
            <b>Quantity:</b>
          </span>
          <span className="w-50">{order.Quantity}</span>
        </div>
        <div className="d-flex">
          <span className="w-50">
            <b>Completed Quantity:</b>
          </span>
          <span className="w-50">{order.CompletedQuantity}</span>
        </div>
        <div className="d-flex">
          <span className="w-50">
            <b>Delivered Quantity:</b>
          </span>
          <span className="w-50">{order.DeliveredQuantity}</span>
        </div>
      </div>
    </Modal>
  );
};

export default ModalHardStop;
